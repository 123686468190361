<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
           <el-breadcrumb-item>房间管理</el-breadcrumb-item>
           <el-breadcrumb-item>房间设备</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="content-box">
        <!-- 查询框 -->
        <el-row class="search-box">
           <el-row class="search-row">
              <el-row class="search-item">
                 <label>楼栋/单元：</label>
                 <el-cascader
                       class="width-220 m-right-10"
                       :key="buildKey"
                       v-model="buildUnitId"
                       :options="buildList"
                       @change="changeBuild"
                       clearable></el-cascader>
              </el-row>
              <el-row class="search-item">
                 <label>房间类型：</label>
                 <el-select   @focus="getRoomType" v-model="roomTypeId" :placeholder="$t('msg.select')" clearable>
                    <el-option v-for="item in roomList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                 </el-select>
              </el-row>
              <el-row class="search-item">
                 <label>房间号：</label>
                 <el-input  class="width-220" v-model="roomNo" :placeholder="$t('msg.room_number')"  clearable></el-input>
              </el-row>
           </el-row>
        </el-row>
        <!-- 主体内容 -->
        <el-row class="table-box">
           <!-- 表格按钮 -->
           <el-row class="table-head-btn">
              <el-button-group class="m-left-10 m-right-10">
                 <el-button :type="alarmState === '' ? 'primary' : ''" class="m-bottom-5" @click="alarmState = ''" ><span v-text="$t('msg.all_room')">所有房间</span></el-button>
                 <el-button :type="alarmState === 'NORMAL' ? 'primary' : ''" class="m-bottom-5" @click="alarmState = 'NOMAL'" ><span v-text="$t('msg.normal_room')">正常房间</span></el-button>
                 <el-button :type="alarmState === 'UNNORMAL' ? 'primary' : ''" class="m-bottom-5" @click="alarmState = 'UNUSUAL'" ><span v-text="$t('msg.abnormal_room')">异常房间</span></el-button>
              </el-button-group>
              <el-row class="right-btn">
                 <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery" ><span v-text="$t('msg.search')">搜索</span></el-button>
                 <el-button class="m-bottom-5" type="primary" icon="el-icon-refresh-right" @click="handleReset"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
              </el-row>
           </el-row>
           <hotel-floor :floorOpt="floors" @setFloorClick="getFloorClick"/>
           <template>
                <div v-loading="loading" class="list-box">
                  <div class="no-data" v-if="noData" v-text="$t('msg.no_data')">暂无数据</div>
                  <div class="room-box" v-for="(item ,idx) in tableData" v-bind:key="idx">
                     <!-- 正常房间 -->
                    <div v-if="item.alarmState === 'NOMAL'" class="room-item" :id="item.id" v-on:mouseover="hover($event)" v-on:mouseout="out()" @click="handleJump(item)">
                      <p>
                         <span>房间号：{{item.roomNo}}</span>
                         <span style="float: right; padding-right: 6px">
                           <el-tooltip content="已入住" placement="top"><i v-if="item.status[0] === '1'" class="iconfont icontianjiayonghu"></i></el-tooltip>
                           <el-tooltip content="已插卡" placement="top"><i v-if="item.status[1] === '2'" class="iconfont iconfangqiazhuanhuan-"></i></el-tooltip>
                           <el-tooltip content="清理" placement="top"><i v-if="item.status[2] === '3'" class="iconfont iconqingli"></i></el-tooltip>
                           <el-tooltip content="勿扰" placement="top"><i v-if="item.status[3] === '4'" class="iconfont iconwuraofuzhi"></i></el-tooltip>
                         </span>
                      </p>
                      <div class="num-box">
                        <div>
                          <p v-text="$t('msg.total_nubmer')">设备总数量</p>
                          <p class="val-p">{{item.roomDeviceState.deviceCount || 0}}</p>
                        </div>
                        <div>
                          <p v-text="$t('msg.roomtype')">房型</p>
                          <p class="val-p">{{item.roomTypeName}}</p>
                        </div>
                      </div>
                      <div class="status-box">
                        <div>
                          <p>
                            <span class="box-span bg-green"></span>
                            <span v-text="$t('msg.online')">在线</span>
                            <span v-text="item.roomDeviceState.deviceOnlineCount || 0"></span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <span class="box-span bg-red"></span>
                            <span v-text="$t('msg.offline')">离线</span>
                            <span v-text="item.roomDeviceState.deviceOfflineCount || 0"></span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <span class="box-span bg-gray"></span>
                            <span v-text="$t('msg.inactivated')">未激活</span>
                            <span v-text="item.roomDeviceState.deviceUnactiveCount || 0"></span>
                          </p>
                        </div>
                      </div>
                      <span class="bgLine" :class="{'greeBg':active === item.id}"></span>
                    </div>
                    <!-- 异常房间 -->
                    <div v-else-if="item.alarmState === 'UNUSUAL'" class="room-item abnormity-item" :id="item.id" v-on:mouseover="hover($event)" v-on:mouseout="out()"  @click="handleJump(item)">
                      <p>
                        <span>房间号：{{item.roomNo}}</span>
                        <span style="float: right; padding-right: 6px">
                          <el-tooltip content="已入住" placement="top"><i v-if="item.status[0] === '1'" class="iconfont icontianjiayonghu"></i></el-tooltip>
                          <el-tooltip content="已插卡" placement="top"><i v-if="item.status[1] === '2'" class="iconfont iconfangqiazhuanhuan-"></i></el-tooltip>
                          <el-tooltip content="清理" placement="top"><i v-if="item.status[2] === '3'" class="iconfont iconqingli"></i></el-tooltip>
                          <el-tooltip content="勿扰" placement="top"><i v-if="item.status[3] === '4'" class="iconfont iconwuraofuzhi"></i></el-tooltip>
                        </span>
                      </p>
                      <div class="num-box">
                        <div>
                          <p v-text="$t('msg.total_nubmer')">设备总数量</p>
                          <p class="val-p">{{item.roomDeviceState.deviceCount || 0}}</p>
                        </div>
                        <div>
                          <p v-text="$t('msg.roomtype')">房型</p>
                          <p class="val-p">{{item.roomTypeName}}</p>
                        </div>
                      </div>
                      <div class="status-box">
                        <div>
                          <p>
                            <span class="box-span bg-green"></span>
                            <span v-text="$t('msg.online')">在线</span>
                            <span v-text="item.roomDeviceState.deviceOnlineCount || 0"></span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <span class="box-span bg-red"></span>
                            <span v-text="$t('msg.offline')">离线</span>
                            <span v-text="item.roomDeviceState.deviceOfflineCount || 0"></span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <span class="box-span bg-gray"></span>
                            <span v-text="$t('msg.inactivated')">未激活</span>
                            <span v-text="item.roomDeviceState.deviceUnactiveCount || 0"></span>
                          </p>
                        </div>
                      </div>
                      <span class="bgLine" :class="{'redBg':active === item.id}"></span>
                    </div>
                    <!-- 未激活 -->
                    <div v-else class="room-item gray-item" :id="item.id" v-on:mouseover="hover($event)" v-on:mouseout="out()">
                      <p>
                        <span>房间号：{{item.roomNo}}</span>
                        <span style="float: right; padding-right: 6px">
                          <el-tooltip content="已入住" placement="top"><i v-if="item.status[0] === '1'" class="iconfont icontianjiayonghu"></i></el-tooltip>
                          <el-tooltip content="已插卡" placement="top"><i v-if="item.status[1] === '2'" class="iconfont iconfangqiazhuanhuan-"></i></el-tooltip>
                          <el-tooltip content="清理" placement="top"><i v-if="item.status[2] === '3'" class="iconfont iconqingli"></i></el-tooltip>
                          <el-tooltip content="勿扰" placement="top"><i v-if="item.status[3] === '4'" class="iconfont iconwuraofuzhi"></i></el-tooltip>
                        </span>
                      </p>
                      <div class="num-box">
                        <div>
                          <p v-text="$t('msg.total_nubmer')">设备总数量</p>
                          <p class="val-p">{{item.roomDeviceState.deviceCount || 0}}</p>
                        </div>
                        <div>
                          <p v-text="$t('msg.roomtype')">房型</p>
                          <p class="val-p">{{item.roomTypeName}}</p>
                        </div>
                      </div>
                      <div class="status-box">
                        <div>
                          <p>
                            <span class="box-span bg-green"></span>
                            <span v-text="$t('msg.online')">在线</span>
                            <span v-text="item.roomDeviceState.deviceOnlineCount || 0"></span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <span class="box-span bg-red"></span>
                            <span v-text="$t('msg.offline')">离线</span>
                            <span v-text="item.roomDeviceState.deviceOfflineCount || 0"></span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <span class="box-span bg-gray"></span>
                            <span v-text="$t('msg.inactivated')">未激活</span>
                            <span v-text="item.roomDeviceState.deviceUnactiveCount || 0"></span>
                          </p>
                        </div>
                      </div>
                      <span class="bgLine" :class="{'grayBg':active === item.id}"></span>
                    </div>
                  </div>
                </div>
              </template>
          <!-- 分页 -->
          <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
        </el-row>
     </el-row>
  </section>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   import { mapState } from 'vuex'
   export default {
      computed:{
         ...mapState(['hotelInfo'])
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelId = this.hotelInfo.id
                  this.getDevices()
               }
            }
         },
         buildList(newVal){
            if(newVal.length !== 0) this.buildKey = newVal[0].value
         }
      },
      data(){
         return {
            // sideData: [],       // 侧边栏数据
            tableData: [],      // 表格数据
            buildList: [],      // 楼栋列表
            floors: [],         // 楼层列表
            emptyFloor: [],     // 空楼层列表
            roomList: [],       // 房间列表
            // sideLoad: true,     // 侧边栏加载效果
            loading: true,      // 表格加载效果
            total: 0,           // 表格数据总数
            noData: true,       // 数据提示状态
            active: '',         // 鼠标当前状态
            storageHid: '',     // 存储酒店ID
            buildKey: '',       // 楼栋联级器索引
            boxIcon: true,      // 酒店显示
            /* 获取列表字段 */
            limit: 0,           // 每页数
            page: 1,            // 当前页
            hotelId: '',        // 酒店ID
            alarmState: '',      // 房间设备状态
            buildUnitId: [],    // 楼栋ID
            floor: '',          // 楼层
            roomTypeId: '',     // 房间类型ID
            roomNo: '',         // 房号
         }
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1 // 每页显示数
         this.hotelId = this.hotelInfo.id
         this.getBuildings()
         this.getDevices()
      },
      inject: ['reload'],
      methods: {
         // 获取房间设备列表
         getDevices(){
            const url = urlObj.getRoomDevicePage
            const param = {
               limit: this.limit,
               page: this.page,
               alarmState: this.alarmState,
               hotelId: this.hotelId,
               buildUnitId: this.buildUnitId[1],
               floor: this.floor,
               roomTypeId: this.roomTypeId,
               roomNo: this.roomNo
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  if (tableData.length === 0) return this.tableData = []
                  this.noData = false
                  tableData.forEach(item => {
                     if (!item.hasOwnProperty('roomDeviceState')){
                        item['roomDeviceState'] = {}
                        item['roomDeviceState'].deviceCount = 0
                        item['roomDeviceState'].deviceOnlineCount = 0
                        item['roomDeviceState'].deviceOfflineCount = 0
                        item['roomDeviceState'].deviceUnactiveCount = 0
                     }

                     item.status = item.status.split(',')
                  })
                  this.tableData = tableData
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 搜索设备
         handleQuery(){
            this.page = 1
            this.getDevices()
         },
         // 重置搜索
         handleReset(){
            this.buildUnitId = []
            this.roomTypeId = ''
            this.roomNo = ''
            this.floors = this.emptyFloor
            this.alarmState = ''
         },
         // 改变每页数
         changePageNum(num){
            this.limit = num
            this.getDevices()
         },
         // 改变当前页
         changeCurrPage(num){
            this.page = num
            this.getDevices()
         },
         // 获取楼栋单列表
         getBuildings(){
            const url = urlObj.buildAndUnitList + `/${this.hotelId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let buildList = res.records
                  this.buildList = buildList.map(item => {
                     let children = []
                     if (item.buildUnits.length > 0){
                        children = item.buildUnits.map(item => {
                           return {value: item.id, label: item.unitName}
                        })
                     }
                     return {value: item.id, label: item.buildName, children}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 改变当前楼栋列表
         changeBuild(val){
            this.getFloors(val[1])
         },
         // 楼层下拉框(表头)
         getFloors(unitVal){
            const url = urlObj.getUnit + `/${unitVal}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let data = res.records
                  let beginFloor = data.beginFloor
                  let endFloor = data.endFloor
                  if (!endFloor || endFloor <= 0) return
                  let floors = JSON.parse(JSON.stringify(this.emptyFloor))
                  let obj = {}
                  for (let i = beginFloor; i <= endFloor; i++) {
                     obj.value = i
                     obj.label = i
                     floors.push(obj)
                     obj = {}
                  }
                  this.floors = floors
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 楼层点击
         getFloorClick(val) {
            this.floor = val.floor
            this.getDevices()
         },
         // 获取房间类型
         getRoomType(){
            if (this.storageHid === this.hotelId) return
            this.storageHid = this.hotelId
            const url = urlObj.getHotelRoomTypeList
            let param = {
               hotelId: this.hotelId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let roomList = res.records
                  this.roomList = roomList.map(item => {
                     return {value: item.roomTypeId, label: item.roomTypeName}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 跳转设备列表
         handleJump(row){
            sessionStorage.setItem('menuIndex', '5')
            sessionStorage.setItem('sideIndex', '2-0-0')
            this.$router.push(
               {
                  path: '/hotel_all/all',
                  query: {
                     hotelId: row.hotelId,
                     roomId: row.roomDeviceState.roomId,
                     need: 'return'
                  }
               }
            )
            this.reload()
         },
         // 鼠标经过
         hover(e){
            this.active = e.currentTarget.id
         },
         // 鼠标离开
         out(){
            this.active = ''
         },
      }
   }
</script>
<style scoped lang="scss">
  .gree-btn{
    background:#009688;
    border-color: #009688;
    color:white;
    &:hover, &:focus {
      color: #1ABC9C ;
      border-color: #baebe1;
      background-color: #e8f8f5;
      opacity: 0.8;
    }
    &:active{
      opacity: 1!important;
    }
  }
  .room-list-box{
    /* min-height: 700px; */
    background: white;
    /* padding: 10px; */
  }
  .list-box{
    /* border: 1px solid red; */
    box-sizing: border-box;
    /* min-height: 660px; */
    /* min-width: 1300px; */
    max-width: 1680px;
    /* margin: 0px auto; */
  }
  .list-box:after{
    content: '';
    display: block;
    clear: both;
  }
  .no-data{
    text-align: center;
    color: #909399;
    font-size: 12px;
    line-height: 60px;
  }
  .room-box{
    /* border: 1px solid black; */
    box-sizing: border-box;
    width: 25%;
    /* max-width: 332px; */
    height: 240px;
    float: left;
    padding: 10px 10px 20px;
    /* margin: 10px; */
  }
  .room-item{
    border: 1px solid #009688;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 14px;
    height: 100%;
    /* min-width: 240px; */
    /* max-width: 310px; */
    cursor: pointer;
    position: relative;
  }
  .bgLine{
    display: block;
    position: absolute;
    bottom: -8px;
    width: 100%;
    /* margin-left: 10%; */
    height: 19px;
  }
  .greeBg{
    background-image: radial-gradient(110px 5px at 50% 60%,hsla(-550,55%,30%,28),hsla(228,100%,0%,0));
  }
  .redBg{
    background-image: radial-gradient(110px 5px at 50% 60%,hsla(-10,85%,65%,28),hsla(122,100%,0%,0));
  }
  .grayBg{
    background-image: radial-gradient(110px 5px at 50% 60%,hsla(-100,17%,88%,28),hsla(222,100%,0%,0));
  }
  .abnormity-item{
    border-color: #f56c6c;
  }
  .gray-item{
    border-color: #DCDFE6;
  }
  .num-box{
    margin: 25px 0;
    overflow: hidden;
  }
  .num-box .val-p{
     font-size: 1.0rem;
     padding-top: 10px;
     font-weight: bold;
  }
  .room-item > p{
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    color: white;
    background: #009688;
  }
  .room-item p i{
     font-size: 18px;
     margin-right: 5px;
  }
  .abnormity-item > p{
    background: #f56c6c;
  }
  .gray-item > p{
    background: #f4f4f4;
    color: black;
     font-size: 16px;
  }
  .room-item > div{
    text-align: center;
  }
  .status-box{
    border-top: 1px solid #DCDFE6;
    padding-top: 10px;
    overflow: hidden;
  }
  .num-box > div{
    width: 50%;
    float: left;
  }
  .status-box > div{
    width: 33.3%;
    float: left;
  }
  .status-box p{
    line-height: 40px;
  }
  .status-box p span{
    display: inline-block;
  }
  .status-box p .box-span{
     width: 12px;
     height: 12px;
     margin-top: 2px;
  }
  .status-box p span:nth-child(2){
     margin: 0 5px;
     font-size: 0.8rem;
  }
  @media (max-width:1024px){
    .room-box{
      width: 50%;
      height: 220px;
    }
    .num-box{
      margin: 20px 0;
    }
  }
  @media screen and (min-width:1024px) and (max-width:1366px){
    .room-box{
      width: 33%;
      height: 230px;
    }
    .num-box{
      margin: 15px 0;
    }
  }
</style>
